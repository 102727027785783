<template>
  <!-- invoice:filter START -->
  <div
    :class="[
      'filter-options',
      { 'active' : activeFilter }
    ]"
  >
    <div
      class="close"
      v-on:click="triggerFilter"
    >X</div>
    <div
      class="headline"
      v-html="$t('account.invoice.filter')"
    ></div>
    <!-- invoice:filter:options START -->
    <ul>

      <!-- invoice:filter:options:standard-options START -->
      <li
        v-for="(option, optionIndex) in options"
        :key="`options-option-${optionIndex}`"
        v-on:click="triggerFilterData(option.data); activeOption = optionIndex"
        :class="[
          { 'active' : activeOption === optionIndex }
        ]"
      >
        <div
          class="before"
          :style="`background-image: url(${iconCorrect})`"
        ></div>
        <span v-html="option.headline"></span>
      </li>
      <!-- invoice:filter:options:standard-options END -->

      <!-- invoice:filter:options:custom-option START -->
      <li
        v-on:click="activeOption = -2"
        :class="[
          { 'active' : activeOption === -2 }
        ]"
      >
        <div
          class="before"
          :style="`background-image: url(${iconCorrect})`"
        ></div>
        <span
          class="demonstration"
          v-html="$t('account.invoice.demonstration')"
        ></span>
        <el-date-picker
          v-on:change=" triggerFilterData(dateTimePickerChild);"
          v-model="dateTimePickerChild"
          type="datetimerange"
          start-placeholder="Start"
          end-placeholder="End"
          :default-time="['00:00:00', '23:00:00']">
        </el-date-picker>
      </li>
      <!-- invoice:filter:options:custom-option END -->

    </ul>
    <!-- invoice:filter:options END -->
  </div>
  <!-- invoice:filter END -->
</template>

<script>
import ICONS from '../../utils/icons';

const {
  iCorrect,
} = ICONS;

export default {
  name: 'invoice-filter',
  props: {
    activeFilter: {
      type: Boolean,
    },

    triggerFilter: {
      type: Function,
      default: () => null,
    },

    triggerFilterData: {
      type: Function,
      default: () => null,
    },

    dateTimePicker: {
      type: Array,
    },
  },
  data() {
    return {
      iconCorrect: localStorage && localStorage.getItem('theme') === 'light' ? iCorrect.defaultUrl : iCorrect.greenUrl,
      dateTimePickerChild: this.dateTimePicker,
      activeOption: 0,
      options: [
        {
          headline: this.$t('account.invoice.options.all'),
          data: '',
        },
        {
          headline: this.$t('account.invoice.options.day30'),
          data: this.$date(new Date()).subtract(30, 'day'),
        },
        {
          headline: this.$t('account.invoice.options.month3'),
          data: this.$date(new Date()).subtract(3, 'month'),
        },
        {
          headline: this.$t('account.invoice.options.month6'),
          data: this.$date(new Date()).subtract(6, 'month'),
        },
        {
          headline: this.$t('account.invoice.options.yearlast'),
          data: this.$date(new Date()).subtract(12, 'month'),
        },
      ],
    };
  },
};
</script>
